var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.20.4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/coresite";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import { errorTagName, errorTagValue } from '@/utils/errorHandling'

/* can't name import from 'process' in browser */
const SENTRY_DSN = process.env.SENTRY_DSN
const NEXT_PUBLIC_APP_ENV = process.env.NEXT_PUBLIC_APP_ENV
const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
    dsn: SENTRY_DSN || NEXT_PUBLIC_SENTRY_DSN,
    environment: NEXT_PUBLIC_APP_ENV,
    /* DO NOT SET `release` here. The environment variable `SENTRY_RELEASE` is set in the github build
    workflow for consistency in events and sourceap uploads. The value is based on the image name (for prod)
    or the workflow number (lower envs). */

    /* Adjust this value in production to 1.0 to capture 100%, or use tracesSampler for greater control */
    tracesSampleRate: 0.0,
    beforeSend(event) {
        /* Review filtering options: https://docs.sentry.io/platforms/javascript/configuration/filtering */
        /* Review Event payload Types: https://develop.sentry.dev/sdk/event-payloads/types/ */
        try {
            // Filter out client error that we have not tagged (allow listed)
            if (event?.tags[errorTagName] !== errorTagValue) {
                return null
            }
        } catch (error) {
            // Do we need/want this log?
            console.log(`Failed to filter events with mesage: ${error.message}`)
        }
        return event
    }
})
